<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					class="font_Hsemibold"
					>{{ $t("importAlternativeRequest") }}</AppText
				>
			</div>
			<div class="section__top-right d-flex align-center">
				<app-input
					@keypress.enter="toPage()"
					width="200"
					class="filter__search mr-15"
					:placeholder="this.$t('search')"
				>
					<template #prepend>
						<img src="@/assets/icons/search.svg" style="width: 20px" alt />
					</template>
				</app-input>

				<app-button-a class theme="gray-light" padding-x="24" font-weight="600" @click="openFilter">
					<img src="../../assets/icons/sliders.svg" class="mr-10" alt />
					<span>{{ $t("filter") }}</span>
				</app-button-a>
			</div>
		</div>
		<div class="section__top">
			<transition name="fade" class="mb-20">
				<app-content-card
					v-if="isOpenFilterPanel"
					class="auction-filter mb-20"
					border
					:class="isMobile ? 'flex-wrap' : ''"
				>
					<div class="filter__input-section w-100">
						<div class="auction-filter__items-wrap">
							<form-label v-model="pageConfig.filters.nameProduct" :title="this.$t('nameOne')" />

							<form-label v-model="pageConfig.filters.codeTnved" :title="this.$t('tnVed')" />

							<form-label v-model="pageConfig.filters.count" type="number" :title="this.$t('count')" />
							<form-label
								v-model="pageConfig.filters.measureId"
								:list="measures"
								item-value="id"
								component-type="dropdown"
								:title="this.$t('unitMeasurement')"
							/>

							<form-label v-model="pageConfig.filters.standartIso" :title="this.$t('gostStandards')" />
						</div>
						<div class="auction-filter__items-bottom-wrap nd_date_picker">
							<AppDatePicker
								v-model="pageConfig.filters.beginDate"
								:title="this.$t('startDate')"
								placeholder="дд-мм-гггг"
							/>
							<AppDatePicker
								v-model="pageConfig.filters.endDate"
								:title="this.$t('endDate')"
								placeholder="дд-мм-гггг"
							/>
							<form-label v-model="pageConfig.filters.customer" :title="this.$t('customer')" />
						</div>
					</div>

					<div class="auction-filter__buttons-wrap">
						<app-button-a
							:width="0"
							padding-x="10"
							@click="clearAndSearch"
							class="auction-filter__reset-button color-text-light"
							theme="transparent"
						>
							<img src="@/assets/icons/trash.svg" class="mr-10" alt style="width: 18px" />
							Очистить фильтр
						</app-button-a>
						<div class="auction-filter__buttons">
							<app-button-a
								@click="isOpenFilterPanel = false"
								class="mr-15"
								width="108"
								theme="gray-light"
								>{{ $t("cancel") }}</app-button-a
							>
							<app-button-a
								width="108"
								fontWeight="600"
								paddingX="20"
								class="font_Hsemibold"
								@click="toPage()"
								>{{ $t("show") }}</app-button-a
							>
						</div>
					</div>
				</app-content-card>
			</transition>
		</div>
		<div class="section__body plan-graph" :class="isMobileSmall ? 'mb-60' : 'mb-100'">
			<div class="table-block table-responsive" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th>№</th>
							<th style="max-width: 300px">{{ $t("nameOne") }}</th>
							<th style="max-width: 250px">{{ $t("tnVed") }}</th>
							<th>{{ $t("amountShort") }}</th>
							<th>{{ $t("unitMeasurement") }}</th>
							<th>{{ $t("startDate") }}</th>
							<th>{{ $t("endDate") }}</th>
							<th>{{ $t("functionalCharacteristics") }}</th>
							<th>{{ $t("gostStandards") }}</th>
							<th>{{ $t("customer") }}</th>
							<!-- <th style="text-align: center">{{ $t("Действие") }}</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, idx) in tableContent" :key="idx">
							<td>{{ idx + 1 }}</td>
							<td style="max-width: 300px">{{ item.nameProduct[$store.state.clng] }}</td>
							<td style="max-width: 250px" class="text-center">{{ item.codeTnved }}</td>
							<td class="text-center">{{ item.count }}</td>
							<td class="text-center">{{ item.measureName }}</td>
							<td>{{ item.beginDate | moment("DD-MM-YYYY HH:mm:ss") }}</td>
							<td>{{ item.endDate | moment("DD-MM-YYYY HH:mm:ss") }}</td>
							<td>{{ item.funcsionalCaracteristic[$store.state.clng] }}</td>
							<td>{{ item.standartIso[$store.state.clng] }}</td>
							<td>{{ item.companyName[$store.state.clng] }}</td>
							<!-- <td class="text-center">
								<AppButton
									theme="main"
									sides="15"
									height="30"
									weight="600"
									:radius="6"
									font-size="14"
									class="font_Hsemibold"
									:disabled="!canSendRequest(item)"
									@click="addProvider(item.id)"
									>{{ $t("Предложить") }}</AppButton
								>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>

			<AppModal
				@ok="close()"
				v-if="modalVisible"
				v-model="modalVisible"
				:closeButton="false"
				ref="planScheduleModal"
				max-width="688"
			>
				<div class="modal__holder">
					<div class="label-input-group mb-30">
						<app-text size="18" weight="600" class="font_Hsemibold"
							>{{ $t("plan_schedule") }} № {{ planSchedule.planNumber }}</app-text
						>
					</div>
					<div class="plan-grid-container">
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("productName") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.productName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("amountShort") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium"
								>{{ planSchedule.productQuantity }} {{ planSchedule.measureName }}</span
							>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("month") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium"
								>{{ planSchedule.month | monthString }} {{ planSchedule.year }}</span
							>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("sourceOfFinancing") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.sourceFinanceName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("region") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.regionName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("district") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.districtName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("address") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{ planSchedule.address }}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("deliveryTime") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.createdDate | moment("DD.MM.YYYY")
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("deliveryConditions") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.deliveryConditionName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("customerInn") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.deliveryConditionName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("customerName") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{
								planSchedule.companyName | translate
							}}</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("customerPhone") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">{{ planSchedule.phones }}</span>
						</div>
					</div>
					<div class="footer_modal">
						<AppButton
							theme="gray-light"
							sides="20"
							height="40"
							weight="600"
							font-size="14"
							class="font_Hsemibold"
							@click="closeModal"
							>{{ $t("close") }}</AppButton
						>
					</div>
				</div>
			</AppModal>
			<div class="page-footer">
				<form-label
					v-model="pageConfig.selectedCount"
					:list="categoryList"
					component-type="dropdown"
					class="mt-0 drop_bag"
					style="min-width: 70px"
					@input="toPage()"
				/>
				<pagination
					:total="pageConfig.elementsCount"
					:perPage="pageConfig.selectedCount"
					@paginate="pagination($event)"
				></pagination>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppButtonA from "../../components/shared-components/AppButtonA";
import FormLabel from "../../components/shared-components/FormLabel";
import AppButton from "../../components/shared-components/AppButton";
import AppPagination from "../../components/shared-components/AppPagination";
import Pagination from "../../components/shared-components/Pagination";
import AppModal from "../../components/shared-components/AppModal";
import ClientService from "@/services/api/client-service";
import ReferenceService from "@/services/api/reference-service";
import AppContentCard from "@/components/shared-components/AppContentCard";
import BlockWrap from "@/components/shared-components/BlockWrap";
import AppMultipleSelect from "@/components/shared-components/AppMultipleSelect";
import months from "@/static/months";
import years from "@/static/years";
import AppInput from "../../components/shared-components/AppInput";
import TokenService from "@/services/TokenService";
import AppDatePicker from "@/components/shared-components/AppDatepicker";

export default {
	name: "index",
	components: {
		AppModal,
		AppPagination,
		AppButton,
		FormLabel,
		AppButtonA,
		AppText,
		Pagination,
		AppContentCard,
		BlockWrap,
		AppMultipleSelect,
		AppInput,
		AppDatePicker,
	},
	data() {
		return {
			query: {
				nameProduct: "",
				codeTnved: "",
				count: null,
				measureId: null,
				standartIso: "",
				beginDate: null,
				endDate: null,
				customer: "",
				pageConfig: {
					activePage: 1,
					skip: 0,
					take: 10,
				},
			},
			pageConfig: {
				filters: {
					nameProduct: "",
					codeTnved: "",
					count: null,
					measureId: null,
					standartIso: "",
					beginDate: null,
					endDate: null,
				},
				activePage: 0,
				elementsCount: 0,
				selectedCount: 10,
			},

			monthList: [],
			queryClear: {},
			total: 0,
			isOpenFilterPanel: false,
			categories: [],
			regions: [],
			months: months,
			years: [],
			quarters: [],
			data: null,
			planSchedule: {},
			categoryList: [
				{
					name: "10",
					value: 10,
				},
				{
					name: "20",
					value: 20,
				},
				{
					name: "30",
					value: 30,
				},
			],
			tableContent: [],
			modalVisible: false,
			measures: [],
		};
	},
	created() {
		this.getMeasures();
		this.toPage();
		this.setYears();
		// this.queryClear = JSON.parse(JSON.stringify(this.pageConfig));
	},
	methods: {
		clearAndSearch() {
			this.pageConfig = {
				filters: {
					nameProduct: "",
					codeTnved: "",
					count: null,
					measureId: null,
					standartIso: "",
					beginDate: null,
					endDate: null,
				},
				activePage: 0,
				elementsCount: 0,
				selectedCount: 10,
			};
			this.toPage();
		},
		canSendRequest(item) {
			let userInfo = this.$store.state.userInfo;
			return userInfo.tin && item.quantityProposals <= 0 && item.companyInn !== userInfo.tin;
		},
		// toPage() {
		//   //   this.query.list = [];
		//   this.$api
		//     .get(`/api-shop/RequestAlternative/GetAll`, { params })
		//     .then(
		//       (response) => {
		//         const res = response.data;
		//         if (res && res.result) {
		//           this.data = res.result.data;
		//           this.total = res.result.total;
		//         } else {
		//           this.$notification.error(res.error.message);
		//         }
		//       },
		//       (error) => {
		//         this.$notification.error(error);
		//       }
		//     )
		//     .finally(() => {
		//       this.loading = false;
		//     });
		// },

		toPage(page = this.pageConfig.activePage, pageCount = this.pageConfig.selectedCount) {
			this.pageConfig.activePage = page;
			this.pageConfig.selectedCount = pageCount;
			let params = {
				...this.pageConfig.filters,
				skip: page,
				take: this.pageConfig.selectedCount,
			};
			this.$store.commit("setLoadingStatus", true);
			this.$api
				.get(`/api-client/RequestAlternative/GetAll`, { params })
				.then(
					(response) => {
						console.log(response.data.result.data);
						this.tableContent = response.data.result.data;
						this.pageConfig.elementsCount = response.data.result.total;
					},
					(error) => {
						// if (error.response.data.error) {
						//   this.$notification.error(error.response.data.error.message);
						// } else {
						//   this.$notification.error();
						// }
					}
				)
				.finally(() => {
					this.$store.commit("setLoadingStatus", false);
				});
		},

		addProvider(id) {
			if (TokenService.getToken()) {
				let params = {
					id,
				};
				this.$api.post("/api-shop/RequestAlternative/AddProvider", params).then(
					(response) => {
						this.$notification.success(this.$i18n.t("notification.success"));
						this.toPage();
					},
					(error) => {}
				);
			} else {
				this.$notification.error(this.$i18n.t("signInAlternativeImport"));
			}
		},

		getMeasures() {
			if (this.measures.length === 0) {
				ReferenceService.getMeasures().then((response) => {
					this.measures = response.data.result.data;
				});
			}
		},

		getRegions() {
			if (this.regions.length > 0) return;

			ReferenceService.getRegions().then(
				(response) => {
					this.regions = response.data.result.data;
				},
				(error) => {
					this.$notify({ type: "error", text: error });
				}
			);
		},
		setYears() {
			const currentYear = +this.$moment(new Date()).format("YYYY");
			this.years = years.filter((year) => year.value >= currentYear);
		},
		pickMonths(e) {
			if (!this.query.hasOwnProperty("list")) {
				this.query["list"] = [];
			}
			if (this.query.list.includes(e.id)) {
				this.query.list = this.query.list.filter((item) => item !== e.id);
			} else {
				this.query.list.push(e.id);
			}
		},
		pagination(skip) {
			console.log(skip);
			this.pageConfig.activePage = skip - this.pageConfig.selectedCount;
			this.toPage();
		},
		openFilter() {
			this.getRegions();
			this.isOpenFilterPanel = !this.isOpenFilterPanel;
		},
		openModal(item) {
			this.planSchedule = item;
			this.modalVisible = !this.modalVisible;
		},
		closeModal() {
			this.modalVisible = !this.modalVisible;
			this.$refs.scheduleModal.close();
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/mixins";
app-content-card {
	width: 100%;
}
.auction-filter {
	display: flex;
	width: 100%;
	grid-column-gap: 25px;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	&__items-wrap {
		width: 100%;
		display: grid;
		grid-template-columns: 2fr 160px 160px 160px 1fr;
		grid-column-gap: 15px;
	}

	&__items-bottom-wrap {
		width: 100%;
		display: grid;
		grid-template-columns: 160px 160px 465px;
		grid-column-gap: 15px;
	}

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
	}

	&__buttons-wrap {
		padding-top: 26px;
		// width: 200px;
		display: flex;
		flex-direction: column;
	}

	&__buttons {
		display: flex;
		width: 231px;
	}
}

.auction-header {
	display: flex;
	align-items: center;

	&__input {
		flex-grow: 1;
		margin-right: 15px;
	}

	&__button {
		svg {
			margin-left: -8px;
		}
	}
}

.drop_bag {
	.app-dropdown {
		&__header {
			background: #dfeffd !important;
		}
	}
}
.footer_modal {
	display: flex;
	justify-content: flex-end;
	padding-top: 40px;
}

.page-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.form-label {
		position: absolute;
		left: 0;
	}
	.pagination-container {
		margin-top: 0;
	}
}

.filter__search {
	width: 450px;
}

.section {
	&__top {
		&:first-child {
			grid-row-gap: 10px;
		}
	}
}

.filter {
	&__input-section {
		display: flex;
		flex-direction: column;
		grid-row-gap: 15px;
	}
}

@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
</style>

